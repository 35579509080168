import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/Index.vue'
const Login = () => import("@/views/login/Login.vue")
// import HomeView from '@/views/home/HomeView.vue'//方式一
const HomeView =()=> import('@/views/home/HomeView.vue') //方式二
const Technology = () => import("@/views/technology/Technology.vue")
const Scene = () => import("@/views/scene/Scene.vue")
const Price = () => import("@/views/price/Price.vue")
const About = () => import("@/views/about/AboutView.vue")
const Contact = () => import("@/views/contact/Contact.vue")
const Activate = () => import("@/views/activate/Activate.vue")
const SceneInfo = () => import("@/views/sceneinfo/Sceneinfo.vue")
const SceneInfo2 = () => import("@/views/sceneinfo/Sceneinfo2.vue")

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return VueRouterPush.call(this, location).catch(err => err)
}
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return VueRouterReplace.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    children:[
      {
        path: '/',
        name: 'home',
        component: HomeView
      },
      {
        path: '/technology',
        name: 'technology',
        component: Technology,
        meta:{
          isLogin:false
        }
      },
       {
        path: '/scene',
        name: 'scene',
        component: Scene,
        meta:{
          isLogin:false
        }
      },
      {
        path: '/price',
        name: 'price',
        component: Price,
        meta:{
          isLogin:false
        }
      },
      {
        path: '/about',
        name: 'about',
        component: About
      },
      {
        path: '/contact',
        name: 'contact',
        component: Contact
      },
      {
        path: '/activate',
        name: 'activate',
        component: Activate,
        meta:{
          isLogin:false
        }
      },
      {
        path: '/sceneinfo',
        name: 'sceneinfo',
        component: SceneInfo,
        meta:{
          isLogin:false
        }
      },
      {
        path: '/sceneinfo2',
        name: 'sceneinfo2',
        component: SceneInfo2,
        meta:{
          isLogin:false
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



export default router


